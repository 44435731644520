import React, { useState } from "react";
import "./styles.css";
import { Link } from "react-router-dom";
import TicketModal from "../Health/addTicket";

const FloatingButton = (props) => {
  return (
    <>
      <TicketModal
        // ticket={ticket}
        home
        modalActive={false}
        modalHandle={() => {}}
        addLoading={() => {}}
      />
    </>
  );
};

export default FloatingButton;
