import { fromJS } from 'immutable';
import actions from './actions';


const initState = fromJS({
  idToken: null,
  profile: null,
});

export default function authReducer(state = initState, action) {
  switch (action.type) {
    case actions.LOGIN_SUCCESS:
      return state
        .set('idToken', action.token)
        .set('profile', action.profile);
    case actions.UPDATE_USER_DATA:
      return state
        .set('profile', action.payload);
    case actions.LOGOUT:
      return initState;
    default:
      return state;
  }
}
