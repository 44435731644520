import styled from "styled-components";

const LayoutContentWrapper = styled.div`
  padding: 20px 20px;
  display: flex;
  flex-flow: row wrap;
  overflow: hidden;
  
  .ant-form-item-label label:after {    
      margin: 0 2px 0 2px;   
  }

  .head-style {
    margin-bottom: 0;
  }

  .icon-wrapper {
    width: 33px;
    height: 32px;
    background: #ececec;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 2px;
  }

  .export-row {  
    display: flex;
    justify-content: flex-end;
    width: 100%;
    align-items: center;   
  }

  .delete-icon svg {
    width: 19px;
    height: 34px;
    margin: 0 5px;
    color: #FF0000;
  }

  .edit-icon svg {
    font-size: 23px;
    margin: 0 5px;
    cursor: pointer;
    color: #1d73b3;
  }

  .export-icon svg {
    font-size: 24px;
    margin: 5px 5px 0 5px;
    cursor: pointer;
    color: #1d73b3;
  }
  .full-height {
    height: 100%;
  }
  .export svg{
    font-size: 24px;
    margin: 5px;
    cursor: pointer;
    color: #1d73b3;
  }

  .add-icon-style svg {
    font-size: 22px;
    cursor: pointer;
    color: #1d73b3;
    margin: 0 5px;
  }

  .icons-row {
    display: flex;
    justify-content: flex-end;
    align-items: center;     
  }
  
  .create-user-form .ant-form-item {
    margin-bottom: 0px;
  }

  .label-width-auto label {
    width: auto !important;
  }

  .text-center{
    text-align: center;
  }

  .chk-box-width {
    min-width: 200px;
  }

  .dob-width span {
    width:100%;
   }

  .export-btn { 
    margin-bottom: 14px;
  }

  .card-box {
    display: flex;
    cursor: pointer;
    width: 47%;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #eee;
    border-radius: 5px;
    padding: 10px;
    margin: 10px;
    min-height: 90px;
    line-break: anywhere;
    position: relative;
  }
  .card-box-details{
    width: 100%;
  }
  .rating-close {
    position: absolute;
    right: 10px;
    top: 10px;
    display:flex;
    align-items: center;
}

  @media only screen and (max-width: 767px) {
    padding: 50px 20px;
  }

  @media (max-width: 580px) {
    padding: 15px;
  }

`;

export { LayoutContentWrapper };
