import React from "react";
import Spins from "../../components/uielements/spin";
import styled from "styled-components";
import { palette } from "styled-theme";
import OdinLogo from "../../image/odinLogo1.png";

const AntSpin = (props) => <Spins indicator={
<img src={OdinLogo} alt="Loading" style={{ width: "50px", height: "50px" }} className="rotate-odin-image1"/>
} {...props} />;

const Spin = styled(AntSpin)`
  && {
    .ant-spin-dot {
      i {
        background-color: ${palette("primary", 0)};
      }
    }
  }
`;

const ModalSpin = styled(AntSpin)`
  width: 100%;
`;

export { ModalSpin };

export default Spin;
