import { message } from "antd";
import { Map } from "immutable";
import isArray from "lodash-es/isArray";
import moment from "moment";
import config from "../settings";
import XLSX from "xlsx";
import _, { differenceWith, isEmpty, orderBy } from "lodash";

export const pageSize = 10;

export const titleRegexp = new RegExp(/^([a-zA-Z0-9 _-]+)$/);
export const urlRegexp = new RegExp(
  /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi
);

export const readMore = (str, size = 40, dots = true) => {
  const hasReadMore = str?.length > size + 3;
  if (hasReadMore) return `${str.substring(0, size)}${dots ? "..." : ""}`;
  return str;
};

export function getSortParam(sorter) {
  try {
    if (sorter.order && sorter.columnKey) {
      const param = {
        sortOrder: sorter.order === "descend" ? "DESC" : "ASC",
        sortBy: sorter.columnKey || "",
      };
      return param;
    }
    return {
      sortOrder: "",
      sortBy: "",
    };
  } catch (err) {
    return {
      sortOrder: "",
      sortBy: "",
    };
  }
}

export const menuList = (list, blockedList, role = "", classrooms) => {
  const listCopy = { ...list };

  blockedList.forEach((el) => {
    const name = el?.displayName
      ?.replace(/[^a-zA-Z ]/g, "")
      .toLowerCase()
      .split(" ")
      .join("");
    if (listCopy[name]) {
      delete listCopy[name];
    }
    if (name === "appversion") delete listCopy["chromeversion"];
  });

  if (role === "ADMIN") {
    delete listCopy["admin"];
    delete listCopy["roles"];
    // delete listCopy["devices"];
  }

  if (classrooms && classrooms.length === 0) {
    delete listCopy["myclassroom"];
  }

  return Object.keys(listCopy).map((el) => listCopy[el]);
};

export const weeks = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];
export const toTitleCase = (str) =>
  str.replace(/\w\S*/g, function(txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });

export const validatePassword = (rule, value, callback) => {
  let regex = new RegExp(
    "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
  );
  if (value && !regex.test(value)) {
    callback("Weak password!");
  } else {
    callback();
  }
};

export const adminDefaultAvatar = "defaults/images/admin.jpg";

export function clearToken() {
  localStorage.clear();
  window.location.reload();
}

export function getToken() {
  try {
    const idToken = localStorage.getItem("id_token");
    return new Map({ idToken });
  } catch (err) {
    clearToken();
    return new Map();
  }
}

export function getProfile() {
  try {
    const profile = JSON.parse(localStorage.getItem("profile"));
    return new Map({ profile });
  } catch (err) {
    clearToken();
    return new Map();
  }
}

export function leaveDateFormat(str) {
  return moment(str).format("DD-MM-YYYY");
}

export function getMonthStartEnd(date) {
  const startOfMonth = moment(date)
    .startOf("month")
    .format("YYYY-MM-DD");
  const endOfMonth = moment(date)
    .endOf("month")
    .add(1, "days")
    .format("YYYY-MM-DD");
  return {
    start: startOfMonth,
    end: endOfMonth,
  };
}

export function getDateStartEnd(date) {
  const start = moment(date).format("YYYY-MM-DD");
  const end = moment(date)
    .add(1, "days")
    .format("YYYY-MM-DD");
  return {
    start,
    end,
  };
}

export function formatPostDate(postDate) {
  return moment(postDate).format("DD-MM-YYYY hh:mm A");
}

export function formatSaleDate(date) {
  return moment(date).format("YYYY-MM-DD");
}

export function serverBaseUrl() {
  return `${config.baseUrl}`;
}

export function bucketUrl() {
  return `${config.s3Url}`;
}

export function genPaswd(firstName) {
  return `${firstName.substring(0, 4)}123`.toLowerCase();
}

export function emailToLink(string) {
  const regEx = /(\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*)/;
  const converted = string.replace(regEx, '<a href="mailto:$1">$1</a>');
  // cons
  return converted;
}

export function formatEvents(events) {
  events.forEach((event, index) => {
    events[index].start = new moment(event.start).toDate();
    events[index].end = new moment(event.end).toDate();
  });
  return events;
}

export function timeDifference(givenTime) {
  givenTime = new Date(givenTime);
  const milliseconds = new Date().getTime() - givenTime.getTime();
  const numberEnding = (number) => {
    return number > 1 ? "s" : "";
  };
  const number = (num) => (num > 9 ? "" + num : "0" + num);
  const getTime = () => {
    let temp = Math.floor(milliseconds / 1000);
    const years = Math.floor(temp / 31536000);
    if (years) {
      const month = number(givenTime.getUTCMonth() + 1);
      const day = number(givenTime.getUTCDate());
      const year = givenTime.getUTCFullYear() % 100;
      return `${day}-${month}-${year}`;
    }
    const days = Math.floor((temp %= 31536000) / 86400);
    if (days) {
      if (days < 28) {
        return days + " day" + numberEnding(days);
      } else {
        const months = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        const month = months[givenTime.getUTCMonth()];
        const day = number(givenTime.getUTCDate());
        return `${day} ${month}`;
      }
    }
    const hours = Math.floor((temp %= 86400) / 3600);
    if (hours) {
      return `${hours} hour${numberEnding(hours)} ago`;
    }
    const minutes = Math.floor((temp %= 3600) / 60);
    if (minutes) {
      return `${minutes} minute${numberEnding(minutes)} ago`;
    }
    return "a few seconds ago";
  };
  return getTime();
}

export function allLanguages() {
  const lang = [
    {
      id: "english",
      value: "English",
    },
    {
      id: "sotho",
      value: "Sotho",
    },
    {
      id: "afrikaans",
      value: "Afrikaans",
    },
    {
      id: "xhosa",
      value: "Xhosa",
    },
    {
      id: "tswana",
      value: "Tswana",
    },
    {
      id: "zulu",
      value: "Zulu",
    },
  ];
  return lang;
}

export const validateNumber = (rule, value, callback) => {
  if (value) {
    var reg = /^[0-9]*$/;
    if (reg.test(value) == true) callback();
    else callback("Invalid number");
  } else {
    callback();
  }
};
export const validatPercentage = (rule, value, callback) => {
  if (value) {
    if (value < 5) {
      callback("Number must be minimum 5");
    } else if (value > 99) {
      callback("Number must be maximum 99");
    } else {
      var reg = /^[0-9]*$/;
      if (reg.test(value) == true) {
        callback();
      } else {
        callback("Invalid number");
      }
    }
  } else {
    callback();
  }
};

export const validatePercentage = (rule, value, callback) => {
  if (value) {
    var reg = /^[0-9]*$/;
    if (reg.test(value) == true) {
      if (value < 0 || value > 100) {
        callback("Only allowed 0 to 100");
      } else {
        callback();
      }
    } else {
      callback("Invalid number");
    }
  } else {
    callback();
  }
};

export const validatAccession = (rule, value, callback) => {
  if (value) {
    var reg = /^[0-9]*$/;
    if (reg.test(value) == true) callback();
    else callback("Invalid number");
  } else callback();
};

export const validatPhone = (rule, value, callback) => {
  if (value) {
    let checkLength = value.substring(0, 3).includes(27) ? 11 : 12; 
    var reg = /^\+?[0-9]*$/;
    if (reg.test(value) == true) {
      let modifiedString = value;
      if (value.charAt(0) === '+') {
        modifiedString = value.substring(1);
      }
      if(modifiedString.substring(0,2) == 27 && modifiedString.substring(2,3) == 0){
        checkLength = checkLength+1
      }
      if (modifiedString.length < checkLength) {
        callback("Number length is too short");
      }else if (modifiedString.length > checkLength){
        callback("Number length is too high");
      } else {
        callback();
      }
    } else {
      callback("Invalid number");
    }
  } else {
    callback();
  }
};
export const validatSIM = (rule, value, callback) => {
  if (value) {
    var reg = /^\+?[0-9]*$/;
    if (reg.test(value) == true) {
      callback();
    } else {
      callback("Invalid number");
    }
  } else {
    callback();
  }
};

export const validateURL = (rule, value, callback) => {
  if (value) {
    const regex = new RegExp(
      "(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?"
    );
    if (regex.test(value)) callback();
    else {
      callback("Invalid URL");
    }
  } else {
    callback();
  }
};

export const validateEmail = (rule, value, callback) => {
  if (value) {
    var reg = /^([A-Za-z0-9_\-\.+])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    if (reg.test(value) == true) {
      callback();
    } else {
      callback("Invalid email ID");
    }
  } else {
    callback();
  }
};
export const validateTime = (rule, value, callback) => {
  if (value) {
    if (value < 1440 && value > 0) {
      callback();
    } else {
      callback("Please enter a value between 0 and 1440");
    }
  } else {
    callback();
  }
};
export const validateDate = (rule, value, callback) => {
  if (value) {
    console.log(value);
    const currentDate = moment();
    if (value.isSameOrAfter(currentDate, 'day')) {
      callback();
    } else {
      callback("Previous dates are not allowed");
    }
  } else {
    callback();
  }
};
export const validateHasTags = (rule, value, callback) => {
  if (value) {
    const isValid = value?.every((value) => value.includes("#", 0));
    if (isValid) {
      callback();
    } else {
      callback("Please select valid hashtags");
    }
  } else {
    callback();
  }
};
export const validateString = (rule, value, callback) => {
  if (value) {
    var reg = /^[0-9]+$/;
    if (reg.test(value) == true) {
      callback("Numbers not allowed");
    } else {
      callback();
    }
  } else {
    callback();
  }
};
export const validateArray = (values, callback) => {
  if (values) {
    let flag = values?.every((value) => {
      if (!value || !value?.trim()) return false;
      return true;
    });
    if (flag) callback();
    else callback("Please fill options");
  } else {
    callback();
  }
};
export const validateArrayComa = (values, callback) => {
  if (values) {
    let flag = values?.every((value) => {
      var reg = /^[^,]*,[^,]*$/;
      return !reg.test(value);
    });
    if (flag) callback();
    else callback("Coma doesn't support in options");
  } else {
    callback();
  }
};

export const allWeeks = () => {
  const weeks = [];
  let week = 1;
  while (week < 53) {
    weeks.push({ id: week, title: `Week ${week}` });
    week++;
  }
  return weeks;
};

export const allAges = () => {
  const ages = [];
  let age = 7;
  while (age < 19) {
    ages.push({ id: age, title: `${age} Years` });
    age++;
  }
  return ages;
};

export const allWeeksLength = () => {
  const weeks = [];
  let week = 1;
  while (week < 53) {
    weeks.push({
      id: week,
      title: `${week}` < 2 ? `${week} Topic` : `${week} Topics`,
    });
    week++;
  }
  return weeks;
};

export const formatObject = (object = {}) => {
  let copyObj = {};
  for (const property in object)
    if (object[property] !== undefined) copyObj[property] = object[property];
  return copyObj;
};
export const formatFormData = (object = {}) => {
  let formData = new FormData();
  for (const property in object) {
    try {
      if (object[property]) {
        if (isArray(object[property])) {
          if (object[property].length > 0)
            formData.append(property, JSON.stringify(object[property]));
        } else formData.append(property, object[property]);
      } else {
        if (object[property] === true || object[property] === false)
          formData.append(property, object[property]);
      }
    } catch (e) {
      console.log(e);
    }
  }
  return formData;
};
export const formatFormData1 = (object = {}) => {
  let formData = new FormData();
  for (const property in object) {
    try {
      if (object[property] || object[property] == "") {
        if (isArray(object[property])) {
          formData.append(property, JSON.stringify(object[property]));
        } else formData.append(property, object[property]);
      } else {
        if (object[property] === true || object[property] === false)
          formData.append(property, object[property]);
      }
    } catch (e) {
      console.log(e);
    }
  }
  return formData;
};

export const filterOption = (input, option) =>
  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

export const filterSort = (list = [], key, order = "asc") =>
  orderBy(list, [(item) => item?.[key]?.toLowerCase()], [order]);

export const minAge = (current, minAge = 7) =>
  current > moment().subtract(minAge, "years");

export const isNotTeacher = (role) =>
  ["ODIN_ADMIN", "SUPER_ADMIN", "ORGANIZATION_ADMIN"].includes(role);

export const searchChildren = {
  showSearch: true,
  optionFilterProp: "children",
};

export const acceptImageTypes = ".jpg,.JPG,.png,.PNG,.jpeg,.JPEG"; //,.gif,.GIF,.svg,.SVG"; //,.webp,.WEBP";
export const acceptXlTypes = ".csv"; //, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"

export const lessonBuilderMenus = [
  // "Lesson Title",
  // "Description",
  // "Objectives",
  "Pre-Lesson",
  "Activity",
  "Test",
];
export const isEqual = (val1, val2) => {
  if (typeof val1 == typeof val2) {
    console.log("equal");
    if (typeof val1 == "string") console.log("string");
    return true;
  }
  return false;
};
export const isEqualArrayValues = (newArr = [], oldArr = []) => {
  return isEmpty(differenceWith(newArr, oldArr, _.isEqual));
};
export const clipString = (string = "", len = 15) => {
  if (string && string.length > len) {
    string = string.slice(0, len - 3) /*.concat("...")*/;
  }
  return string;
};
const range = (start, end) => {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
};

export const isStartWith = (value, search) =>
  value?.toLowerCase()?.startsWith(search?.toLowerCase()) || false;
export const isIncludes = (value, search) =>
  value?.toLowerCase()?.includes(search?.toLowerCase()) || false;

export const disablePastTime = (curr) => {
  const diff = moment(moment().format("L")).diff(curr.format("L"), "days");
  if (diff === 0) {
    return {
      disabledHours: () => range(0, moment().format("H")),
      disabledMinutes: () =>
        moment().format("H") == curr.format("H")
          ? range(0, moment().format("m") - -1)
          : [],
    };
  } else if (diff < 0) {
  } else {
  }
};

const fileNameValidation = (file) => {
  const format = /[!%&?]/;
  const isValid = !format.test(file?.name || "");
  if (!isValid) {
    message.error("The file name is not valid!");
  }
  return isValid;
};

export const beforeImageWithSizeUpload = (file) => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("You can only upload Image file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 1;
  if (!isLt2M) {
    message.error("Image must smaller than 1MB!");
  }
  return isJpgOrPng && isLt2M && fileNameValidation(file);
};

export const beforeImageUpload = (file) => {
  const isJpgOrPng = ["image/jpeg", "image/png"].includes(file.type);
  if (!isJpgOrPng) {
    message.error("You can only upload Image file!");
  }
  const isLt1M = file.size / 1024 / 1024 < 1;
  if (!isLt1M) {
    message.error("Image must smaller than 1MB!");
  }
  return isJpgOrPng && isLt1M && fileNameValidation(file);
};

export const beforePDFUpload = (file) => {
  const isPdf = file.type === "application/pdf";
  if (!isPdf) {
    message.error("You can only upload PDF file!");
  }
  const isLt50M = file.size / 1024 / 1024 < 100;
  if (!isLt50M) {
    message.error("PDF must smaller than 100MB!");
  }
  return isPdf && isLt50M && fileNameValidation(file);
};

export const beforeVideoUpload = (file) => {
  const isVideo = ["video/mp4", "video/x-m4v", "video/*"].includes(file.type);
  if (!isVideo) {
    message.error("You can only upload Video file!");
  }
  const isLt50M = file.size / 1024 / 1024 < 300;
  if (!isLt50M) {
    message.error("Video must smaller than 300MB!");
  }
  return isVideo && isLt50M && fileNameValidation(file);
};

export const beforeUpload = (file) => {
  if (["video/mp4", "video/x-m4v", "video/*"].includes(file.type)) {
    const isVideo = ["video/mp4", "video/x-m4v", "video/*"].includes(file.type);
    if (!isVideo) {
      message.error("You can only upload Video file!");
    }
    const isLt50M = file.size / 1024 / 1024 < 300;
    if (!isLt50M) {
      message.error("Video must smaller than 300MB!");
    }
    return isVideo && isLt50M && fileNameValidation(file);
  } else if (["image/jpeg", "image/png"].includes(file.type)) {
    const isJpgOrPng = ["image/jpeg", "image/png"].includes(file.type);

    if (!isJpgOrPng) {
      message.error("You can only upload Image file!");
    }
    const isLt1M = file.size / 1024 / 1024 < 1;
    if (!isLt1M) {
      message.error("Image must smaller than 1MB!");
    }
    return isJpgOrPng && isLt1M && fileNameValidation(file);
  } else {
    const isPdf = file.type === "application/pdf";
    if (!isPdf) {
      message.error("You can only upload PDF file!");
    }
    const isLt50M = file.size / 1024 / 1024 < 100;
    if (!isLt50M) {
      message.error("PDF must smaller than 100MB!");
    }
    return isPdf && isLt50M && fileNameValidation(file);
  }
};

export const checkValidTestCsv = (file, resolve, reject) => {
  const reader = new FileReader();

  const rABS = !!reader.readAsBinaryString;
  reader.onload = (event) => {
    const bstr = event.target.result;
    const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
    const workSheetName = wb.SheetNames[0];
    const ws = wb.Sheets[workSheetName];
    var range = XLSX.utils.decode_range(ws["!ref"]);
    for (var R = range.s.r; R <= range.e.r; ++R) {
      for (var C = range.s.c; C <= range.e.c; ++C) {
        var coord = XLSX.utils.encode_cell({ r: R, c: C });
        if (ws[coord] === undefined) {
          ws[coord] = { t: "s", v: "", w: "" };
        } else {
        }
      }
    }
    const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
    try {
      let flag = true;
      data?.forEach((item, index) => {
        if (item?.length == 5) {
          if (index === 0) {
            if (
              item[0] == "question" &&
              item[1] == "answer" &&
              item[2] == "weightage" &&
              item[3] == "option" &&
              item[4] == "type"
            ) {
            } else flag = false;
          } else if (item.includes("")) flag = false;
        } else flag = false;
      });
      if (flag) resolve(true);
      else reject(false);
    } catch (e) {
      reject(false);
    }
  };
  if (rABS) reader.readAsBinaryString(file);
  else reader.readAsArrayBuffer(file);
};
const checkTesCSVFile = (file) => {
  return new Promise((resolve, reject) => {
    checkValidTestCsv(file, resolve, reject);
  });
};

export const combineArray = (collection1 = [], collection2 = []) => {
  const list = [...collection1];
  collection2?.forEach((item) => {
    const index = findIndex(collection1, ["_id", item?._id]);
    if (index === -1) list.push(item);
  });
  return list;
};

export function formatAsZARCurrency(number) {
  if (typeof number !== 'number') {
    return 'Invalid input';
  }

  // Convert the number to a string
  var numberString = number.toFixed(0);

  // Split the number string into parts at the decimal point
  var parts = numberString.split('.');

  // Extract the integer part
  var integerPart = parts[0];

  // Add commas as thousands separators
  var formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  // Combine the integer part and the decimal part (if it exists)
  var formattedNumber = parts.length > 1 ? formattedIntegerPart + '.' + parts[1] : formattedIntegerPart;

  return formattedNumber;
}

export const beforeCsvUpload = (file) => {
  const isCsv = [
    "text/csv",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.ms-excel",
  ].includes(file.type);
  if (!isCsv) {
    message.error("You can only upload CSV file!");
  }
  return isCsv && fileNameValidation(file);
};

export const beforeAllUpload = (file) => {
  const isFile = [
    "video/mp4",
    "video/x-m4v",
    "video/*",
    "application/pdf",
    "image/jpeg",
    "image/png",
  ].includes(file.type);

  const isLt50M = file.size / 1024 / 1024 < 50;
  if (!isLt50M) {
    message.error("File must smaller than 50MB!");
  }
  return isFile && isLt50M;
};
export const capitalizeFirstLetter = (string) => {
  return string ? string?.charAt(0).toUpperCase() + string.slice(1) : "";
};

export const convertToData = (file, setData) => {
  const reader = new FileReader();

  const rABS = !!reader.readAsBinaryString;
  reader.onload = (event) => {
    const bstr = event.target.result;
    const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
    const workSheetName = wb.SheetNames[0];
    const ws = wb.Sheets[workSheetName];
    var range = XLSX.utils.decode_range(ws["!ref"]);
    for (var R = range.s.r; R <= range.e.r; ++R) {
      for (var C = range.s.c; C <= range.e.c; ++C) {
        var coord = XLSX.utils.encode_cell({ r: R, c: C });
        if (ws[coord] === undefined) {
          ws[coord] = { t: "s", v: "", w: "" };
        } else {
        }
      }
    }
    const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
    try {
      setData(data);
    } catch (e) {}
  };
  if (rABS) reader.readAsBinaryString(file);
  else reader.readAsArrayBuffer(file);
};

export const sectors = [
  { value: "General", label: "General" },
  { value: "Automotive", label: "Automotive" },
  {
    value: "Construction & Civil Engineering",
    label: "Construction & Civil Engineering",
  },
  { value: "Agriculture", label: "Agriculture" },
  { value: "ICT", label: "ICT" },
  { value: "Renewable", label: "Renewable" },
  { value: "Financial Sector", label: "Financial Sector" },
  { value: "Forestry", label: "Forestry" },
  { value: "Property", label: "Property" },
  { value: "Tourism", label: "Tourism" },
  {
    value: "Marketing Advertising and Communications",
    label: "Marketing Advertising and Communications",
  },
];

export const size = [
  { label: "Generic (R 50 million p/a)", value: "Generic (R 50 million p/a)" },
  { label: "QSE (R 10 -50 million p/a)", value: "QSE (R 10 -50 million p/a)" },
  { label: "EME (R 0 -10 million p/a)", value: "EME (R 0 -10 million p/a)" },
];

export const validatNumber = (rule, value, callback) => {
  if (value) {
    var reg = /^[0-9]*$/;
    if (reg.test(value) == true) {
      if (value.length > 15) {
        callback("Number length is too high");
      } else {
        callback();
      }
    } else {
      callback("Invalid number");
    }
  } else {
    callback();
  }
};

export const getFile = (url) => {
  return new Promise((resolve, reject) => {
    var request = new XMLHttpRequest();
    request.open("GET", url, true);
    request.responseType = "blob";
    request.onload = function() {
      convertToData(request.response, resolve);
    };
    request.onerror = function() {
      reject({
        status: 400,
        statusText: request.statusText,
      });
    };
    request.send();
  });
};

export const getFileType = (path) => {
  const ext = path
    .split(/[#?]/)[0]
    .split(".")
    .pop()
    .trim();
  if (ext === "pdf") return "book";
  else if (["m4v", "avi", "mpg", "mp4"].includes(ext)) return "video";
  return "book";
};

export const months = [
  { label: "Not Known", value: 0 },
  { label: "January", value: 1 },
  { label: "February", value: 2 },
  { label: "March", value: 3 },
  { label: "April", value: 4 },
  { label: "May", value: 5 },
  { label: "June", value: 6 },
  { label: "July", value: 7 },
  { label: "August", value: 8 },
  { label: "September", value: 9 },
  { label: "October", value: 10 },
  { label: "November", value: 11 },
  { label: "December", value: 12 },
];
