import styled from "styled-components";
import { palette } from "styled-theme";
import { transition, borderRadius } from "../../settings/style-util";
import WithDirection from "../../settings/withDirection";

const UserWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  .user-panel {
    display: flex;
    flex-direction: column;
    width: 300px;
    height: 100%;
    padding: 20px;
    margin-right: 10px;
    border-right: 1px solid #ececec;
    .user-avatar {
      overflow: hidden;
      display: inline-block;
      ${"" /* height: 180px; */} width: 175px;
      /* line-height: 180px; */
      margin: 10px auto;
      background: #ececec;
      object-fit: contain;
      text-align: center;
      img {
        max-width: 175px;
        max-height: 170px;
        vertical-align: middle;
        object-fit: contain;
      }
      .ant-upload-select-picture-card {
        width: 175px;
        height: 180px;
        display: inline-table;
      }
    }
    h3 {
      display: block;
      font-size: 20px;
      font-weight: bold;
      justify-content: center;
      text-align: center;
      overflow-wrap: break-word;
      word-wrap: break-word;
      white-space: normal;
    }
    .add-icon {
      position: relative;
      i {
        position: absolute;
        top: 0;
        right: 0;
      }
    }
  }
  .user-details-panel {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 20px;
    label {
      font-weight: 800;
      margin-right: 5px;
      display: inline-block;
      width: 130px;
      line-height: 2em;
    }
    span {
      text-transform: capitalize;
    }
    .no-transform {
      text-transform: none;
    }
  }
  .ant-upload.ant-upload-select-picture-card {
    border-radius: 20px;
  }
`;

export default WithDirection(UserWrapper);
