import { Button, Icon } from "antd";
import React, { useEffect, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import config from "../../settings";
import Notification from "../../components/notification";
import API from "../../helpers/API";

const viewIconStyle = {
  fontSize: 17,
  marginRight: 10,
  cursor: "pointer",
  color: "#67b58f",
};
export default function Copy({ copy, object }) {
  const [url, setUrl] = useState(undefined);
  const fetchUrl = async () => {
    try {
      const resp = await API.courses.getLesson({ id: object?._id, type: copy });
      if (resp.data && resp.data.url)
        setUrl(`${config.portalUrl}/${resp.data.url}`);
    } catch (err) {}
  };
  useEffect(
    () => {
      /*if (copy === "video") setUrl(`${config.portalUrl}/${object?.file}`);
      else
      if (copy === "website") setUrl(object?.url);
      else  */ if (
        copy === "Lesson" ||
        copy === "Book" ||
        copy === "Video" ||
        copy === "Website"
      )
        setUrl(
          `${
            copy === "Website" ? object?.url : config.portalUrl
          }?type=${copy}&id=${object.id}${
            copy == "Book" ? `&title=${object.title}` : ""
          }`
        );
      else setUrl(undefined);
    },
    [object]
  );
  return url ? (
    <CopyToClipboard
      text={url}
      onCopy={() => Notification("success", "Copied To Clipboard")}
    >
      <Button
        shape="circle"
        icon="copy"
        theme="outlined"
        style={viewIconStyle}
        // title="Copy to Clipboard"
        onClick={(e) => e.stopPropagation()}
      />
    </CopyToClipboard>
  ) : null;
}
