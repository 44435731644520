import styled from "styled-components";
import { palette } from "styled-theme";

const LayoutContentStyle = styled.div`
  width: 100%;
  padding: 20px;
  background-color: #ffffff;
  border: 1px solid ${palette("border", 0)};
  height: 100%;
  /* min-height: 80vh; */

  .icon-row-style {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 10px;
  }

  .export-row {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    align-items: center;
  }

  .search-input {
    width: 350px;
    svg {
      color: #bababa;
    }
  }

  .title-cap {
    display: flex;
    align-items: center;
  }

  .badge-identifier {
    background: rgb(0, 97, 148);
    border-radius: 40px;
    // padding: 0.1rem 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 0.5rem;
    font-size: 13px;
    color: white;
  }
`;

export default LayoutContentStyle;
