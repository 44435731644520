export function getIsSuperAdmin(profile) {
  const role = profile?.role?.handle;
  if (role) {
    if (role !== "SUPER_ADMIN") {
      return false;
    } else {
      return true;
    }
  }
  return false;
}

export function getIsTecher(profile) {
  //has one or more classrooms
  return profile?.classrooms?.length > 0;
}

export function getIsSchoolAdmin(profile) {
  // no classroom and has one more schools
  return !getIsTecher(profile) && profile?.schools?.length > 0;
}

export function getIsOrganizationAdmin(profile) {
  // no classroom, no schools and has organization
  return (
    !getIsTecher(profile) &&
    !getIsSchoolAdmin(profile) &&
    profile?.organizations?.length > 0
  );
}

export function getIsOdinAdmin(profile) {
  // no classroom, no schools, no organizations, and role handle is ODIN_ADMIN
  const role = profile?.role?.handle;
  if (role) {
    if (
      role !== "ODIN_ADMIN" &&
      (getIsOrganizationAdmin(profile) ||
        getIsSchoolAdmin(profile) ||
        getIsTecher(profile))
    ) {
      return false;
    } else {
      return true;
    }
  }
  return false;
}
