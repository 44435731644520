import React, { Component } from "react";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import { Row, Col, Upload, Icon, Popconfirm, Modal } from "antd";
import LayoutContentWrapper from "../../components/utility/layoutWrapper";
import LayoutContent from "../../components/utility/layoutContent";
import { getProfile } from "../../redux/auth/selectors";
import Form from "../../components/uielements/form";
import Input from "../../components/uielements/input";
import Button from "../../components/uielements/button";
import Spin from "../../components/Spin/spin.style";
import actions from "../../redux/dashboard/actions";
import actionUser from "../../redux/auth/actions";
import {
  adminDefaultAvatar,
  serverBaseUrl,
  validatePassword,
} from "../../helpers/utility";
import { getIsSuperAdmin } from "../../helpers/roleUtility";
import API from "../../helpers/API";
import createNotification from "../../components/notification";
import CropToolModal from "../../components/CropTool/CropToolModal";
import UpdatePwd from "./updatePwd"

const { uploadAvatar, removeAvatar, changePassword } = actions;
const { updateUserData, updateProfile } = actionUser;

const FormItem = Form.Item;

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: false,
      passwordLoader: false,
      loading: false,
      newPass: undefined,
      confirmPass: undefined,
      avatarList: false,
      clickNew: false,
      clickConfirm: false,
      openModal: false,
    };
  }
  componentDidMount = () => {
    console.log("componentDidMount", this.props);
    if (this.props.profile && this.props.profile.avatar) {
      this.setAvatar(this.props.profile.avatar);
    } else {
      this.setState({ avatarList: null });
    }
  };

  componentWillReceiveProps(nextProps) {
    console.log("nextProps", nextProps);
    console.log(this.props.profile)
    // if (nextProps?.profile?.avatar && !this.state?.avatarList) {
    //   this.setAvatar(nextProps?.profile?.avatar);
    // } else {
    //   this.setState({ avatarList: null });
    // }
  }

  setAvatar = (avatar) => {
    let avatarList;
    if (avatar == adminDefaultAvatar) avatarList = null;
    else
      avatarList = {
        uid: `-1`,
        status: "done",
        url: `${avatar}`,
        thumbUrl: `${avatar}`,
      };
    this.setState({ avatarList });
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = (e) => {
    const { profile } = this.props;
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (err || getIsSuperAdmin(this.props.profile)) {
      } else {
        this.setState({ passwordLoader: true });
        const payload = {
          password: this.state.newPass,
        };
        this.props.changePassword(payload, profile._id, (val) => {
          this.setState({ passwordLoader: false });
          if (val) this.props.form.resetFields();
          this.props.onUpdate()
        });
      }
    });
  };

  handleSubmitProfileUpdate = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        try {
          this.setState({loader: true})
          const payload = this.state.libraryCover ? { ...values, avatar: this.state.libraryCover } : values;
          const res = await API.profile.updateUser(this.props.profile.id, payload) // Update API call
          createNotification('success', res?.message || 'successfully Applied');
          const updatedProfile = { ...this.props.profile, firstName: res?.data?.user?.firstName, lastName: res?.data?.user?.lastName, email: res?.data?.user?.email, phone: res?.data?.user?.phone, avatar: res?.data?.user?.avatar?.url, };
          localStorage.setItem("profile", JSON.stringify(updatedProfile));
          localStorage.setItem("userData", JSON.stringify(updatedProfile));
          this.props.updateUserData(updatedProfile);
          this.props.onUpdate();
          res?.message && this.setState({loader: false})
        } catch (error) {
          createNotification(
            'error',
            error && error.message
              ? error.message
              : 'An error occurred while submitting the form'
          );
        }
      }
    })
  }

  uploadProfileAvatar = ({ data, file, onSuccess }) => {
    const _this = this;
    getBase64(file, (fileUrl) => {
      onSuccess("done", fileUrl);
      this.setState({ openCropTool: true, imgSrc: fileUrl, avatarList: this.state.avatarList });
    });
  };
  uploadAvatar = async (file) => {
    const _this = this;
    // this.loader(true);
    getBase64(file, async (fileUrl) => {
      // onSuccess("done", fileUrl);
      let iconList = Object.assign({}, this.state.iconList);
      iconList = {
        uid: "-1",
        // name: file.name,
        status: "done",
        originFileObj: { type: "image" },
        url: fileUrl,
        thumbUrl: fileUrl,
      };
      // this.setState({

      // });
      const fileData = new FormData();
      fileData.append("file", file);
      try {
        const fileUpload = await API.upload.add(fileData);
        this.setState({
          libraryCover: fileUpload?.data?.id,
          libraryFile: fileUpload?.data?.id,
          avatarList: iconList,
          img: fileUpload?.data?.fileName,
          openCropTool: false
        });
        // this.props.form.setFieldsValue({
        //   avatar: [iconList],
        // });
        // this.loader(false);
      } catch (err) {
        // this.loader(false);
        createNotification(
          "error",
          err && err.error
            ? err.error?.message || err.message
            : "Session timed out. Please login again"
        );
      }
    });
  };

  removeImg = (e) => {
    const { profile } = this.props;
    this.setState({
      avatarList: null,
    });
    this.setState({
      loading: true,
    });
    console.log("remove avatar");
    this.props.removeAvatar({ avatar: "" }, profile._id, (val) => {
      this.setState({ loading: false });
    });
  };

  handleNewEyeClick = (type) => {
    this.setState({ clickNew: !this.state.clickNew });
  };

  handleConfirmEyeClick = (type) => {
    this.setState({ clickConfirm: !this.state.clickConfirm });
  };
  compareToFirstPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && value !== form.getFieldValue("newPass")) {
      callback("Password mismatch!");
    } else {
      callback();
    }
  };
  render() {
    const { avatarList } = this.state;
    const { getFieldDecorator } = this.props.form;

    const cancel = (e) => {
      return false;
    };

    const uploadButton = (
      <div>
        <Icon type={this.state.loading ? "loading" : "plus"} />
        <div className="ant-upload-text">Upload</div>
      </div>
    );

    return (
      <LayoutContentWrapper >
        <LayoutContent>
          {this.state?.openCropTool ? (
            <CropToolModal
              openCropModal={this.state?.openCropTool}
              setOpenCropModal={this.setOpenCropModal}
              imgSrc={this.state.imgSrc}
              imageName={this.state?.avatarList?.originFileObj?.name}
              submitImage={this.uploadAvatar}
              imgToCrop={this.state.avatarList}
              fileError={""}
              aspect={4 / 4}
            />
          ) : (
            ""
          )}
          <Row>
            <Col span={24}>
              <Col span={4}>
                {/* <h2>Profile Avatar</h2> */}
                <Spin spinning={this.state.loading}>
                  <div className="user-avatar">
                    {/* <Upload
                      name="monday"
                      accept=".jpg,.JPG,.png,.PNG"
                      showUploadList={false}
                      listType="picture-card"
                      className="avatar-uploader"
                      customRequest={this.uploadProfileAvatar}
                    >
                      {avatarList ? (
                        <img
                          src={avatarList.thumbUrl}
                          alt="avatar"
                          style={{ width: "100%" }}
                        />
                      ) : (
                        uploadButton
                      )}
                    </Upload> */}
                    <FormItem label="Avatar">
                      {getFieldDecorator("avatar", {
                        // initialValue: this.state.avatarList,
                        valuePropName: "fileList",
                        getValueFromEvent: (e) => e && e.fileList,
                      })(
                        <Upload
                          name="avatar"
                          accept=".jpg,.png"
                          listType="picture-card"
                          showUploadList={false}
                          customRequest={this.uploadProfileAvatar}
                          onRemove={() => this.setState({ avatarList: null })}
                        >
                          {this.state.avatarList ? <img
                            src={avatarList.thumbUrl}
                            alt="avatar"
                            style={{ width: "100%" }}
                          /> : (
                            <div>
                              <Icon type="plus" />
                              <div className="ant-upload-text">Upload</div>
                            </div>
                          )}
                        </Upload>
                      )}
                    </FormItem>
                  </div>
                </Spin>
              </Col>
              <Col span={20}>
                {/* <h2>User Details</h2> */}
                <Form onSubmit={this.handleSubmitProfileUpdate}>
                  <Row gutter={16}> {/* Add gutter for spacing */}
                    <Col span={12}>
                      <FormItem label="First Name">
                        {getFieldDecorator("firstName", {
                          initialValue: this.props.profile.firstName,
                          rules: [{ required: true, message: "Please enter your first name!" }],
                        })(<Input size="large" placeholder="First Name" />)}
                      </FormItem>
                    </Col>
                    <Col span={12}>
                      <FormItem label="Last Name">
                        {getFieldDecorator("lastName", {
                          initialValue: this.props.profile.lastName,
                          rules: [{ required: true, message: "Please enter your last name!" }],
                        })(<Input size="large" placeholder="Last Name" />)}
                      </FormItem>
                    </Col>
                  </Row>
                  <Row gutter={16}> {/* Add gutter for spacing */}
                    <Col span={12}>
                      <FormItem label="Email">
                        {getFieldDecorator("email", {
                          initialValue: this.props.profile.email,
                          rules: [
                            {
                              required: true,
                              message: "Please enter your email!"
                            },
                            {
                              type: "email",
                              message: "The input is not valid E-mail!",
                            },
                            {
                              validator: (rule, value, callback) => {
                                // Custom validation logic if needed
                                if (value && value.endsWith("@example.com")) {
                                  callback("Email domain '@example.com' is not allowed!");
                                } else {
                                  callback();
                                }
                              },
                            },
                          ],
                        })(<Input size="large" placeholder="Email" />)}
                      </FormItem>
                    </Col>
                    <Col span={12}>
                      <FormItem label="Phone">
                        {getFieldDecorator("phone", {
                          initialValue: this.props.profile.phone,
                          rules: [{ required: true, message: "Please enter your phone number!" }],
                        })(<Input size="large" placeholder="Phone" />)}
                      </FormItem>
                    </Col>
                  </Row>
                  <Row>
                    <div className="flx-space-bwn">
                      <FormItem>
                        <Button
                          type="primary"
                          htmlType="submit"
                          loading={this.state.loader}
                          // disabled={getIsSuperAdmin(this.props.profile)}
                          title="Submit"
                        >
                          Update
                        </Button>
                      </FormItem>
                      <Button
                        onClick={() => this.setState({ openModal: true })}
                      >
                        Update Password
                      </Button>
                    </div>
                  </Row>
                </Form>
              </Col>
            </Col>
            {/* <Modal
              visible={this.state.openModal}
              onCancel={() => this.setState({ openModal: false })}
              closeButton
              title={"Update Password"}
              // footer={null}
              onOk={this.handleSubmit}
              // destroyOnClose
              maskClosable={false}
              // okButtonProps={{ loading: submitting }}
              closable
              zIndex={10002}
            > */}
            <UpdatePwd visible={this.state.openModal}
              onCancel={() => this.setState({ openModal: false })} 
              onUpdate={() => this.props.onUpdate()}
              profile={this.props.profile}
            />
            {/* </Modal> */}
          </Row>
        </LayoutContent>
      </LayoutContentWrapper>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  profile: getProfile(),
});

function mapDispatchToProps(dispatch, ownProps) {
  return {
    uploadAvatar: (payload, userId, cb) =>
      dispatch(uploadAvatar(payload, userId, cb)),
    changePassword: (payload, userId, cb) =>
      dispatch(changePassword(payload, userId, cb)),
    removeAvatar: (payload, userId, cb) =>
      dispatch(removeAvatar(payload, userId, cb)),
    // fetchDevices: (spinHandler) => dispatch(fetchDevices(spinHandler)),
    updateUserData: ((payload) =>
      dispatch(updateUserData(payload))
    )
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)((Profile = Form.create()(Profile)));
