import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 8px;
  align-items: flex-start; /* center wont work in course detail page */
  width: 100%;
`;
export { Container };
