const actions = {
  CHECK_AUTHORIZATION: 'CHECK_AUTHORIZATION',
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGOUT: 'LOGOUT',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERROR: 'LOGIN_ERROR',
  RESET: "RESET",
  UPDATE_PROFILE: "UPDATE_PROFILE",
  AVATAR_UPDATE_SUCCESS: "AVATAR_UPDATE_SUCCESS",
  UPDATE_USER_DATA : "UPDATE_USER_DATA",
  UPDATE_USER_DATA_UPDATE: "UPDATE_USER_DATA_UPDATE",
  checkAuthorization: () => ({ type: actions.CHECK_AUTHORIZATION }),
  updateProfile: (payload) => ({ type: actions.UPDATE_PROFILE, payload }),
  login: (payload, spinHandler) => ({
    type: actions.LOGIN_REQUEST,
    payload,
    spinHandler
  }),
  updateUserData: (payload) => ({
    type: actions.UPDATE_USER_DATA,
    payload,
  }),
  logout: () => ({
    type: actions.LOGOUT
  })
};
export default actions;
